<template>
  <div class="row justify-content-center">
    <div class="col-md-8">
      <div class="card">
        <form @submit.prevent="createTask()">
          <div class="card-header">
            <h4>Create Task</h4>
          </div>
          <div class="card-body pb-0">
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label>Title</label>
                  <input type="text" class="form-control" v-model="fields.title"/>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label>Description</label>
                  <textarea
                    class="form-control"
                    v-model="fields.description"
                  ></textarea>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label>Assign to</label>
                  <div class="form-group">
                    <select class="form-control" v-model="fields.assign_to">
                      <option value="" disabled>Select an option</option>
                      <option
                        v-for="employee in employees"
                        :value="employee.id"
                        :key="employee.id"
                      >
                        {{ employee.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label>Priority</label>
                  <div class="form-group">
                    <select class="form-control" v-model="fields.priority">
                      <option value="" disabled>Select an option</option>
                      <option value="low">Low</option>
                      <option value="medium">Medium</option>
                      <option value="high">High</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label>Start date</label>
                  <input
                    type="datetime-local"
                    class="form-control"
                    v-model="fields.start_date"
                  />
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label>Due date</label>
                  <input
                    type="datetime-local"
                    class="form-control"
                    v-model="fields.due_date"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label>Upload File</label>
                  <div class="custom-file" v-if="!this.fields.file">
                    <input type="file" class="custom-file-input" id="customFile" @change="uploadPassport">
                    <label class="custom-file-label" for="customFile">Choose file</label>
                  </div>
                  <div class="d-flex justify-content-between align-items-center" v-if="this.fields.file">
                    <h5 style="margin-bottom: 0px">File uploaded</h5>
                    <a href="#" class="btn btn-icon btn-sm btn-danger" @click.prevent="removeFile">
                      <i class="fas fa-times"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label>Add sub task</label>
                  <div class="input-group mb-3">
                    <input type="text" class="form-control" placeholder="Go to the store" v-model="singleTask">
                    <div class="input-group-append">
                      <button class="btn btn-default" type="button" @click.prevent="addChecklist()">+ Add</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" v-if="fields.checklist.length > 0">
              <div class="col">
                <div class="table-responsive">
                  <table class="table table-striped table-md">
                    <tr>
                      <th>#</th>
                      <th>Title</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                    <tr v-for="(item, index) in fields.checklist" :key="index">
                      <td>{{ index + 1 }}</td>
                      <td>{{ item.title }}</td>
                      <td><div class="badge badge-danger">Not completed</div></td>
                      <td>
                        <a href="#" class="btn btn-icon btn-sm btn-danger" @click.prevent="removeTask(index)">
                          <i class="fas fa-times"></i>
                        </a>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer text-right pt-0">
            <button class="btn btn-primary" :class="{ 'btn-progress': loading }">Submit</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import TaskHelpers from "@/functions/actions/TaskRequest";
import GeneralHelpers from "@/functions/actions/GeneralRequest";
export default {
  name: "CreateTask",
  computed: {
    ...mapState(['loading', 'userType'])
  },
  mounted() {
    if (this.userType !== 'admin') {
      this.showAlert('You are not permitted to access the page')
      this.$router.go(-1)
    }
    this.retrieveEmployees();
  },
  data() {
    return {
      singleTask: '',
      employees: [],
      fields: {
        assign_to: "",
        priority: "",
        file: undefined,
        checklist: []
      },
    };
  },
  methods: {
    addChecklist() {
      if (this.singleTask) {
        this.fields.checklist = [...this.fields.checklist, { title: this.singleTask}]
        this.singleTask = ''
      } else {
        this.showAlert("Validation Error", "Kindly enter a task", "error")
      }
    },
    uploadPassport(event) {
      // Upload the task file
      this.fields.file = event.target.files[0];
    },
    removeFile() {
      this.$confirm("Are you sure? You can't undo this action").then(async () => {
        this.fields.file = ""
      });
    },
    removeTask(taskIndex) {
      this.fields.checklist.splice(taskIndex, 1)
    },
    async retrieveEmployees() {
      const response = await GeneralHelpers.getUsersByType("employee");
      if (response.status) {
        this.employees = response.users;
      } else {
        this.showAlert(
          "Error occured",
          `${response.message}. Kindly reload the page`,
          "error"
        );
      }
    },
    getFormData(payload) {
      const formData = new FormData();
      Object.keys(payload).forEach((key) => formData.append(key, payload[key]));
      return formData;
    },
    async createTask() {
      if (this.validateData()) {
        const payload = { ...this.fields };
        payload.checklist = JSON.stringify(payload.checklist);
        const response = await TaskHelpers.createTask(this.getFormData(payload));
        if (response.status) {
          this.showAlert("Success", "Tasks has been created successfully", "success")
          this.$router.push({
            name: "Tasks"
          })
        } else {
          this.showAlert(
            "Error occured",
            `${response.message}. Kindly reload the page`,
            "error"
          );
        }
      }
    },
    validateData() {
      if (this.fields.title) {
        if (this.fields.description) {
          if (this.fields.start_date) {
            if (this.fields.due_date) {
              if (this.fields.assign_to) {
                if (this.fields.priority) {
                  // if (this.fields.file) {
                    if (this.fields.checklist.length > 0) {
                      return true
                    } else {
                      this.showAlert(
                        "Validation error",
                        "Ouutline the task",
                        "warning"
                      );
                    }
                  // } else {
                  //   this.showAlert(
                  //     "Validation error",
                  //     "Attach a file",
                  //     "warning"
                  //   );
                  // }
                } else {
                  this.showAlert(
                    "Validation error",
                    "Select priority level",
                    "warning"
                  );
                }
              } else {
                this.showAlert(
                  "Validation error",
                  "Assign task to an employee",
                  "warning"
                );
              }
            } else {
              this.showAlert(
                "Validation error",
                "Select a due date",
                "warning"
              );
            }
          } else {
            this.showAlert(
              "Validation error",
              "Select date of commencement",
              "warning"
            );
          }
        } else {
          this.showAlert(
            "Validation error",
            "Provide details of task",
            "warning"
          );
        }
      } else {
        this.showAlert(
          "Validation error",
          "Briefly describe the task info",
          "warning"
        );
      }
    },
    showAlert(title, text, type) {
      this.$fire({
        title,
        text,
        type: type ? type : "warning",
      });
    },
  },
};
</script>
<style lang="">
</style>